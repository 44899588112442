<template>
  <v-container class="pa-0 fill-height d-flex align-center justify-center">
    <v-card class="ma-4 overflow-hidden" max-width="500">
      <!-- Card Image Header -->
      <v-img
        src="@/assets/alert.svg"
        height="200px"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)"
      >
       
      </v-img>

       <v-card-title class="headline font-weight-bold">
          Suscripción Expirada
        </v-card-title>
        <v-divider/>

      <!-- Card Content -->
      <v-card-text class="body-1 pa-4">
        <p class="text-center" style="font-size: 18px; line-height: 1.6;">
          Tu suscripción ha expirado. Renueva tu suscripción para continuar disfrutando de nuestros servicios.
        </p>

        <!-- Payment Button -->
        <v-btn
         
          color="primary"
          dark
          large
          block
          @click="$router.push('/pago')"
        >
          <v-icon left>mdi-credit-card</v-icon>
          Pago con Tarjeta
        </v-btn>


        <p class="text-center mt-4" style="font-size: 18px; line-height: 1.6;">
          Ponte en contacto para renovar tu suscripción. Recuerda que pasados 15 días desde el fin de tu suscripción, tu cuenta será dada de baja.
        </p>

        <!-- WhatsApp Contact Button -->
        <v-btn
          
          color="green darken-1"
          dark
          large
          block
          href="https://wa.me/+59898996900?text=Hola!%20Quiero%20renovar%20mi%20suscripción"
          target="_blank"
        >
          <v-icon left>mdi-whatsapp</v-icon>
          Contacto WhatsApp
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.v-card-text p {
  margin: 0;
}
</style>
